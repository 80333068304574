import Vue from 'vue'

Vue.mixin({
    data: () => ({
        usuario: {}
    }),
    methods: {
        diasArray(){
            return [
                {
                    id:1, nombre : 'Domingo'
                },
                {
                    id:2, nombre : 'Lunes'
                },
                {   
                    id:3, nombre : 'Martes'
                },
                {
                    id:4, nombre : 'Miercoles'
                },
                {   
                    id:5, nombre : 'Jueves'
                },
                {   
                    id:6, nombre : 'Viernes'
                },
                {   
                    id:7, nombre : 'Sabado'
                }
            ];
        },
        clonar(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        loadPerfil() {
            this.$http.get(`/api/perfil`).then(response => {
                this.$store.commit('SET_USER', response.data)
            })
        },
        formatoMoneda(cantidad) {
            if (!cantidad) {
                cantidad = 0;
            }
            return new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
                useGrouping: true
            }).format(cantidad)
        },
        formatoPorcentaje(cantidad) {
            return new Intl.NumberFormat("es-MX", {
                style: "percent",
            }).format(cantidad)
        },
        formatoNumero(cantidad) {
            return new Intl.NumberFormat('es-MX', {
                style: 'decimal',
                maximumFractionDigits: 2,
            }).format(cantidad)
        }
    }
})