import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            redirect: '/login'
        },
        {
            name: 'Login',
            path: '/login',
            component: () => import('@/views/auth/Login')
        },
        {
            name: 'ResetRequest',
            path: '/password/request',
            component: () => import('@/views/auth/Request')
        },
        {
            name: 'ResetUpdate',
            path: '/password/reset/:token',
            component: () => import('@/views/auth/Reset')
        },
        {
            path: '/dashboard',
            component: () => import('@/views/dashboard/Index'),
            children: [
                // Dashboard
                {
                    name: 'Dashboard',
                    path: 'dashboard',
                    component: () => import('@/views/dashboard/Dashboard'),
                },
                // Pages
                {
                    name: "Perfil",
                    path: '/dashboard/perfil',
                    component: () => import("@/views/dashboard/pages/UserProfile")
                },
                {
                    name: 'Emisor',
                    path: '/dashboard/catalogos/emisores',
                    component: () => import('@/views/dashboard/catalogos/emisor/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Emisores" },
                        ]
                    }
                },
                {
                    name: 'EmisorCreate',
                    path: '/dashboard/catalogos/emisores/create',
                    component: () => import('@/views/dashboard/catalogos/emisor/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Emisores", href: "/dashboard/catalogos/emisores" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'EmisorEdit',
                    path: '/dashboard/catalogos/emisores/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/emisor/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Emisores", href: "/dashboard/catalogos/emisores" },
                            { text: "Editar" },
                        ]
                    }
                },
                {
                    name: 'Lista',
                    path: '/dashboard/catalogos/listas',
                    component: () => import('@/views/dashboard/catalogos/lista/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Listas de precios" },
                        ]
                    }
                },
                {
                    name: 'ListaCreate',
                    path: '/dashboard/catalogos/listas/create',
                    component: () => import('@/views/dashboard/catalogos/lista/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Listas de precios", href: "/dashboard/catalogos/listas" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'ListaEdit',
                    path: '/dashboard/catalogos/listas/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/lista/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Listas de precios", href: "/dashboard/catalogos/listas" },
                            { text: "Editar" },
                        ]
                    }
                },
                {
                    name: 'Impuesto',
                    path: '/dashboard/catalogos/impuestos',
                    component: () => import('@/views/dashboard/catalogos/impuesto/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Impuestos" },
                        ]
                    }
                },
                {
                    name: 'ImpuestoCreate',
                    path: '/dashboard/catalogos/impuestos/create',
                    component: () => import('@/views/dashboard/catalogos/impuesto/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Impuestos", href: "/dashboard/catalogos/impuestos" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'ImpuestoEdit',
                    path: '/dashboard/catalogos/impuestos/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/impuesto/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Impuestos", href: "/dashboard/catalogos/impuestos" },
                            { text: "Editar" },
                        ]
                    }
                },
              {
                name: 'MotivoNoVisita',
                path: '/dashboard/catalogos/motivos_no_visita',
                component: () => import('@/views/dashboard/catalogos/motivo_no_visita/Index'),
                meta: {
                  breadcrumbs: [
                    { text: "Catálogos" },
                    { text: "Motivo no visita" },
                  ]
                }
              },
              {
                name: 'MotivoNoVisitaCreate',
                path: '/dashboard/catalogos/motivos_no_visita/create',
                component: () => import('@/views/dashboard/catalogos/motivo_no_visita/Form'),
                meta: {
                  breadcrumbs: [
                    { text: "Catálogos" },
                    { text: "Motivo no visita", href: "/dashboard/catalogos/motivos_no_visita" },
                    { text: "Crear" },
                  ]
                }
              },
              {
                name: 'MotivoNoVisitaEdit',
                path: '/dashboard/catalogos/motivos_no_visita/:id/edit',
                component: () => import('@/views/dashboard/catalogos/motivo_no_visita/Form'),
                meta: {
                  breadcrumbs: [
                    { text: "Catálogos" },
                    { text: "Motivo no visita", href: "/dashboard/catalogos/motivos_no_visita" },
                    { text: "Editar" },
                  ]
                }
              },
              {
                name: 'Rutas',
                path: '/dashboard/catalogos/rutas',
                component: () => import('@/views/dashboard/catalogos/ruta/Index'),
                meta: {
                  breadcrumbs: [
                    { text: "Catálogos" },
                    { text: "Rutas" },
                  ]
                }
              },
              {
                name: 'RutasCreate',
                path: '/dashboard/catalogos/rutas/create',
                component: () => import('@/views/dashboard/catalogos/ruta/Form'),
                meta: {
                  breadcrumbs: [
                    { text: "Catálogos" },
                    { text: "Rutas", href: "/dashboard/catalogos/rutas" },
                    { text: "Crear" },
                  ]
                }
              },
              {
                name: 'RutasEdit',
                path: '/dashboard/catalogos/rutas/:id/edit',
                component: () => import('@/views/dashboard/catalogos/ruta/Form'),
                meta: {
                  breadcrumbs: [
                    { text: "Catálogos" },
                    { text: "Rutas", href: "/dashboard/catalogos/rutas" },
                    { text: "Editar" },
                  ]
                }
              },
                {
                    name: 'Familia',
                    path: '/dashboard/catalogos/productos-familias',
                    component: () => import('@/views/dashboard/catalogos/familia/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos" },
                            { text: "Familias" },
                        ]
                    }
                },
                {
                    name: 'FamiliaCreate',
                    path: '/dashboard/catalogos/productos/familias/create',
                    component: () => import('@/views/dashboard/catalogos/familia/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos" },
                            { text: "Familias", href: "/dashboard/catalogos/productos-familias" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'FamiliaEdit',
                    path: '/dashboard/catalogos/productos/familias/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/familia/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos" },
                            { text: "Familias", href: "/dashboard/catalogos/productos-familias" },
                            { text: "Editar" },
                        ]
                    }
                },
                {
                    name: 'Producto',
                    path: '/dashboard/catalogos/productos',
                    component: () => import('@/views/dashboard/catalogos/producto/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos" },
                        ]
                    }
                },
                {
                    name: 'ProductoCreate',
                    path: '/dashboard/catalogos/productos/create',
                    component: () => import('@/views/dashboard/catalogos/producto/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos", href: "/dashboard/catalogos/productos" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'ProductoEdit',
                    path: '/dashboard/catalogos/productos/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/producto/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos", href: "/dashboard/catalogos/productos" },
                            { text: "Editar" },
                        ]
                    }
                },
                {
                    name: 'ProductoPrecios',
                    path: '/dashboard/catalogos/productos/:id/precios',
                    component: () => import('@/views/dashboard/catalogos/producto/Precios'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos", href: "/dashboard/catalogos/productos" },
                            { text: "Precios" },
                        ]
                    }
                },
                // {
                //   name: 'ProductoAddendas',
                //   path: '/dashboard/catalogos/productos/:id/addendas',
                //   component: () => import('@/views/dashboard/catalogos/producto/addenda/Index')
                // },
                {
                    name: 'Subfamilia',
                    path: '/dashboard/catalogos/productos-subfamilias',
                    component: () => import('@/views/dashboard/catalogos/subfamilia/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos" },
                            { text: "Subfamilias" },
                        ]
                    }
                },
                {
                    name: 'SubfamiliaCreate',
                    path: '/dashboard/catalogos/productos/subfamilias/create',
                    component: () => import('@/views/dashboard/catalogos/subfamilia/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos" },
                            { text: "Subfamilias", href: "/dashboard/catalogos/productos-subfamilias" },
                            { text: "Crear" }
                        ]
                    }
                },
                {
                    name: 'SubfamiliaEdit',
                    path: '/dashboard/catalogos/productos/subfamilias/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/subfamilia/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Productos" },
                            { text: "Subfamilias", href: "/dashboard/catalogos/productos-subfamilias" },
                            { text: "Editar" }
                        ]
                    }
                },
                {
                    name: 'VendedorCategoria',
                    path: '/dashboard/catalogos/vendedores-categorias',
                    component: () => import('@/views/dashboard/catalogos/vendedor/categoria/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores" },
                            { text: "Categorías" },
                        ]
                    }
                },
                {
                    name: 'VendedorCategoriaCreate',
                    path: '/dashboard/catalogos/vendedores-categorias/create',
                    component: () => import('@/views/dashboard/catalogos/vendedor/categoria/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores" },
                            { text: "Categorías", href: "/dashboard/catalogos/vendedores-categorias" },
                            { text: "Crear" }
                        ]
                    }
                },
                {
                    name: 'VendedorCategoriaEdit',
                    path: '/dashboard/catalogos/vendedores-categorias/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/vendedor/categoria/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores" },
                            { text: "Categorías", href: "/dashboard/catalogos/vendedores-categorias" },
                            { text: "Editar" }
                        ]
                    }
                },
                {
                    name: 'VendedorSubcategoria',
                    path: '/dashboard/catalogos/vendedores-subcategorias',
                    component: () => import('@/views/dashboard/catalogos/vendedor/subcategoria/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores" },
                            { text: "Subcategorías" },
                        ]
                    }
                },
                {
                    name: 'VendedorSubcategoriaCreate',
                    path: '/dashboard/catalogos/vendedores-subcategorias/create',
                    component: () => import('@/views/dashboard/catalogos/vendedor/subcategoria/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores" },
                            { text: "Subcategorías", href: "/dashboard/catalogos/vendedores-subcategorias" },
                            { text: "Crear" }
                        ]
                    }
                },
                {
                    name: 'VendedorSubcategoriaEdit',
                    path: '/dashboard/catalogos/vendedores-subcategorias/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/vendedor/subcategoria/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores" },
                            { text: "Subcategorías", href: "/dashboard/catalogos/vendedores-subcategorias" },
                            { text: "Editar" }
                        ]
                    }
                },
                {
                    name: 'Vendedor',
                    path: '/dashboard/catalogos/vendedores',
                    component: () => import('@/views/dashboard/catalogos/vendedor/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores" },
                        ]
                    }
                },
                {
                    name: 'VendedorCreate',
                    path: '/dashboard/catalogos/vendedores/create',
                    component: () => import('@/views/dashboard/catalogos/vendedor/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores", href: "/dashboard/catalogos/vendedores" },
                            { text: "Crear" }
                        ]
                    }
                },
                {
                    name: 'VendedorEdit',
                    path: '/dashboard/catalogos/vendedores/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/vendedor/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Vendedores", href: "/dashboard/catalogos/vendedores" },
                            { text: "Editar" }
                        ]
                    }
                },
                {
                    name: 'ClienteCategoria',
                    path: '/dashboard/catalogos/clientes-categorias',
                    component: () => import('@/views/dashboard/catalogos/cliente/categoria/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes" },
                            { text: "Categorías", href: "/dashboard/catalogos/clientes-subcategorias" },
                        ]
                    }
                },
                {
                    name: 'ClienteCategoriaCreate',
                    path: '/dashboard/catalogos/clientes-categorias/create',
                    component: () => import('@/views/dashboard/catalogos/cliente/categoria/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes" },
                            { text: "Categorías", href: "/dashboard/catalogos/clientes-categorias" },
                            { text: "Crear" }
                        ]
                    }
                },
                {
                    name: 'ClienteCategoriaEdit',
                    path: '/dashboard/catalogos/clientes-categorias/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/cliente/categoria/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes" },
                            { text: "Categorías", href: "/dashboard/catalogos/clientes-categorias" },
                            { text: "Editar" }
                        ]
                    }
                },
                {
                    name: 'Cliente',
                    path: '/dashboard/catalogos/clientes',
                    component: () => import('@/views/dashboard/catalogos/cliente/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes" },
                        ]
                    }
                },
                {
                    name: 'ClienteCreate',
                    path: '/dashboard/catalogos/clientes/create',
                    component: () => import('@/views/dashboard/catalogos/cliente/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes", href: "/dashboard/catalogos/clientes" },
                            { text: "Crear" }
                        ]
                    }
                },
                {
                    name: 'ClienteEdit',
                    path: '/dashboard/catalogos/clientes/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/cliente/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes", href: "/dashboard/catalogos/clientes" },
                            { text: "Editar" }
                        ]
                    }
                },
                {
                    name: 'ClienteSucursal',
                    path: '/dashboard/catalogos/clientes/:cliente/sucursales',
                    component: () => import('@/views/dashboard/catalogos/cliente/sucursal/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes", href: "/dashboard/catalogos/clientes" },
                            { text: "Sucursales" }
                        ]
                    }
                },
                {
                    name: 'ClienteSucursalCreate',
                    path: '/dashboard/catalogos/clientes/:cliente/sucursales/create',
                    component: () => import('@/views/dashboard/catalogos/cliente/sucursal/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes", href: "/dashboard/catalogos/clientes" },
                            { text: "Sucursales" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'ClienteSucursalEdit',
                    path: '/dashboard/catalogos/clientes/:cliente/sucursales/:sucursal/edit',
                    component: () => import('@/views/dashboard/catalogos/cliente/sucursal/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Clientes", href: "/dashboard/catalogos/clientes" },
                            { text: "Sucursales" },
                            { text: "Editar" },
                        ]
                    }
                },
                {
                    name: 'Almacenes de Vendedores',
                    path: '/dashboard/catalogos/impuestos',
                    component: () => import('@/views/dashboard/catalogos/impuesto/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Almacenes" },
                            { text: "Vendedores" },
                        ]
                    }
                },
                {
                    name: 'AlmacenesCarga',
                    path: '/dashboard/almacenes/carga-descarga',
                    component: () => import('@/views/dashboard/almacenes/cargas/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Almacenes" },
                            { text: "Cargas - Descargas" },
                        ]
                    }
                },
                {
                    name: 'AlmacenesCargaCreate',
                    path: '/dashboard/almacenes/carga-descarga/create',
                    component: () => import('@/views/dashboard/almacenes/cargas/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Almacenes" },
                            { text: "Cargas - Descargas", href: "/dashboard/almacenes/carga-descarga" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'AlmacenesCargaEdit',
                    path: '/dashboard/almacenes/carga-descarga/:id/:tipo',
                    component: () => import('@/views/dashboard/almacenes/cargas/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Almacenes" },
                            { text: "Cargas - Descargas", href: "/dashboard/almacenes/carga-descarga" },
                            { text: "Editar" },
                        ]
                    }
                },
                {
                    name: 'AlmacenesDescarga',
                    path: '/dashboard/almacenes/devoluciones',
                    component: () => import('@/views/dashboard/almacenes/descargas/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Almacenes" },
                            { text: "Devoluciones" },
                        ]
                    }
                },
                {
                    name: 'AlmacenesDescargaCreate',
                    path: '/dashboard/almacenes/devoluciones/create',
                    component: () => import('@/views/dashboard/almacenes/descargas/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Almacenes" },
                            { text: "Devoluciones", href: "/dashboard/almacenes/devoluciones" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'AlmacenesDescargaEdit',
                    path: '/dashboard/almacenes/descargas/:id/',
                    component: () => import('@/views/dashboard/almacenes/descargas/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Almacenes" },
                            { text: "Descargas", href: "/dashboard/almacenes/descargas" },
                            { text: "Editar" },
                        ]
                    }
                },
                {
                    name: 'Metas',
                    path: '/dashboard/metas',
                    component: () => import('@/views/dashboard/metas/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Metas" },
                        ]
                    }
                },
                {
                    name: 'Documento',
                    path: '/dashboard/documentos',
                    component: () => import('@/views/dashboard/documentos/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Documentos" },
                        ]
                    }
                },
                {
                    name: 'DocumentoCreate',
                    path: '/dashboard/documentos/create',
                    component: () => import('@/views/dashboard/documentos/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Documentos" },
                            { text: "Crear" },
                        ]
                    }
                },
                {
                    name: 'DocumentoMovimientos',
                    path: '/dashboard/documentos/:id/movimientos',
                    component: () => import('@/views/dashboard/documentos/Movimientos'),
                    meta: {
                        breadcrumbs: [
                            { text: "Documentos", href: "/dashboard/documentos" },
                            { text: "Movimientos" },
                        ]
                    }
                },
                {
                    name: 'User Profile',
                    path: 'pages/user',
                    component: () => import('@/views/dashboard/pages/UserProfile'),
                },
                {
                    name: "Usuario",
                    path: '/dashboard/acl/usuarios',
                    component: () => import('@/views/dashboard/acl/usuario/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Usuarios" },
                        ]
                    }
                },
                {
                    name: "UsuarioCreate",
                    path: '/dashboard/acl/usuarios/create',
                    component: () => import('@/views/dashboard/acl/usuario/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Usuarios", href: "/dashboard/acl/usuarios" },
                            { text: "Crear" }
                        ]
                    }
                },
                {
                    name: "UsuarioEdit",
                    path: '/dashboard/acl/usuarios/:id/edit',
                    component: () => import('@/views/dashboard/acl/usuario/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: "Catálogos" },
                            { text: "Usuarios", href: "/dashboard/acl/usuarios" },
                            { text: "Editar" }
                        ]
                    }
                },
                {
                    name: 'ReporteGeneralVentas',
                    path: '/dashboard/reportes/ventas/general',
                    component: () => import('@/views/dashboard/reportes/ventas/General'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Ventas' },
                            { text: 'General' },
                        ]
                    }
                },
                {
                    name: 'ReporteSellInSellOutDetallado',
                    path: '/dashboard/reportes/ventas/sellin-sellout/detallado',
                    component: () => import('@/views/dashboard/reportes/ventas/sellin-sellout/Detallado'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Ventas' },
                            { text: 'Sell In - Sell Out' },
                            { text: 'Detallado' },
                        ]
                    }
                },
                {
                    name: 'ReporteSellIn',
                    path: '/dashboard/reportes/ventas/sellin-sellout/sell-in',
                    component: () => import('@/views/dashboard/reportes/ventas/sellin-sellout/SellIn'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Ventas' },
                            { text: 'Sell In - Sell Out' },
                            { text: 'Sell In' },
                        ]
                    }
                },
                {
                    name: 'ReporteSellOut',
                    path: '/dashboard/reportes/ventas/sellin-sellout/sell-out',
                    component: () => import('@/views/dashboard/reportes/ventas/sellin-sellout/SellOut'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Ventas' },
                            { text: 'Sell In - Sell Out' },
                            { text: 'Sell Out' },
                        ]
                    }
                },
                {
                    name: 'ReporteGeneralFacturas',
                    path: '/dashboard/reportes/facturas/general',
                    component: () => import('@/views/dashboard/reportes/facturas/General'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Facturas' },
                            { text: 'General' },
                        ]
                    }
                },
                {
                    name: 'ReporteCxC',
                    path: '/dashboard/reportes/ventas/cxc',
                    component: () => import('@/views/dashboard/reportes/ventas/CxC'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Ventas' },
                            { text: 'CxC' },
                        ]
                    }
                },
                {
                    name: 'ReporteGeneralVendedoresAlmacenes',
                    path: '/dashboard/reportes/vendedores/almacenes',
                    component: () => import('@/views/dashboard/reportes/almacenes_vendedores/General'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Vendedores' },
                            { text: 'Almacenes' },
                        ]
                    }
                },
                {
                    name: 'ReporteGeneralDevoluciones',
                    path: '/dashboard/reportes/devoluciones',
                    component: () => import('@/views/dashboard/reportes/devoluciones/General'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Devoluciones' },
                        ]
                    }
                },
                {
                    name: 'ReporteGeneralRutas',
                    path: '/dashboard/reportes/rutas/general',
                    component: () => import('@/views/dashboard/reportes/rutas/General'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Reportes' },
                            { text: 'Rutas' },
                            { text: 'General' },
                        ]
                    }
                },
              {
                name: 'ReporteGeneralRutasNoVisita',
                path: '/dashboard/reportes/rutas/no_visitas',
                component: () => import('@/views/dashboard/reportes/rutas/NoVisitas'),
                meta: {
                  breadcrumbs: [
                    { text: 'Reportes' },
                    { text: 'Rutas' },
                    { text: 'No Visita' },
                  ]
                }
              },
              {
                name: 'ReporteGeneralHorasVisitas',
                path: '/dashboard/reportes/rutas/horas_visitas',
                component: () => import('@/views/dashboard/reportes/rutas/HorasVisitas'),
                meta: {
                  breadcrumbs: [
                    { text: 'Reportes' },
                    { text: 'Rutas' },
                    { text: 'Horas Visitas' },
                  ]
                }
              },
                {
                    name: 'Cuenta',
                    path: '/dashboard/catalogos/cuentas',
                    component: () => import('@/views/dashboard/catalogos/cuenta/Index'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Catálogos' },
                            { text: 'Cuentas' },
                        ]
                    }
                },
                {
                    name: 'CuentaCreate',
                    path: '/dashboard/catalogos/cuentas/create',
                    component: () => import('@/views/dashboard/catalogos/cuenta/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Catálogos' },
                            { text: 'Cuentas', href: "/dashboard/catalogos/cuentas" },
                            { text: 'Crear' },
                        ]
                    }
                },
                {
                    name: 'CuentaEdit',
                    path: '/dashboard/catalogos/cuentas/:id/edit',
                    component: () => import('@/views/dashboard/catalogos/cuenta/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Catálogos' },
                            { text: 'Cuentas', href: "/dashboard/catalogos/cuentas" },
                            { text: 'Editar' },
                        ]
                    }
                },
                {
                    name: 'Notificaciones',
                    path: '/dashboard/notificaciones/create',
                    component: () => import('@/views/dashboard/notificacion/Form'),
                    meta: {
                        breadcrumbs: [
                            { text: 'Notificaciones' },
                        ]
                    }
                },
                {
                    name: 'Icons',
                    path: 'components/icons',
                    component: () => import('@/views/dashboard/component/Icons'),
                },
                {
                    name: 'Typography',
                    path: 'components/typography',
                    component: () => import('@/views/dashboard/component/Typography'),
                },
                // Tables
                {
                    name: 'Regular Tables',
                    path: 'tables/regular-tables',
                    component: () => import('@/views/dashboard/tables/RegularTables'),
                },
                // Maps
                {
                    name: 'Google Maps',
                    path: 'maps/google-maps',
                    component: () => import('@/views/dashboard/maps/GoogleMaps'),
                },
                // Upgrade
                {
                    name: 'Upgrade',
                    path: 'upgrade',
                    component: () => import('@/views/dashboard/Upgrade'),
                },
            ],

        },
    ],
})
