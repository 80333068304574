// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCfzn_2sG3lDZ25GKnpA__6w98hjr_HGS0',
    libraries: 'places', 
  },
})

import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import '@fortawesome/fontawesome-free/scss/brands.scss'
import '@fancyapps/fancybox/dist/jquery.fancybox.css';


window.moment = require('moment')
window.jQuery = require('jquery')

require('@fancyapps/fancybox/dist/jquery.fancybox')

Vue.prototype.moment = moment

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

Vue.prototype.$http.baseURL = `/`;

Vue.prototype.$http.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('interceptor->error', error);
    switch (error.response.status) {
        case 401:
            localStorage.removeItem('token')
            router.push({ name: 'Login' })
            break;
        case 403:
            store.commit('SET_SNACKBAR', { text: "No autorizado", color: 'red' })
            router.push({ path: "/dashboard" })
            break;
        case 412:
            store.commit('SET_SNACKBAR', { text: error.response.data.message, color: 'red' })
            break;
        case 400:
            store.commit('SET_SNACKBAR', { text: error.response.data.message, color: 'red' })
            break;
        default:
            break;
    }

    return Promise.reject(error);
});

if (localStorage.getItem('token')) {
    Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
    router.push({ name: 'Dashboard' })
}

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     const token = localStorage.getItem('token');
//     if (token) {
//       window.axios.defaults.headers.Authentication = `Bearer ${token}`;
//       next()
//     } else {
//       next({ name: 'login' })
//     }
//   } else {
//     next()
//   }
// });

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app')
