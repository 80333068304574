import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
        barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
        drawer: null,
        user: {},
        snackbar: {
            timeout: 2000,
            text: "",
            show: false,
            color: "success"
        }
    },
    mutations: {
        SET_BAR_IMAGE(state, payload) {
            state.barImage = payload
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload
        },
        SET_USER(state, payload) {
            state.user = payload
        },
        SET_SNACKBAR(state, { text, color }) {
            state.snackbar.text = text
            state.snackbar.color = color
            state.snackbar.show = true
        }
    },
    actions: {

    },
})
